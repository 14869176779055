<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import { badgeColor, statusLabel } from "@/util/mapfunction.js";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
// import InputModal from "@/components/modals/input-modal";
// import AddModal from "./add-modal.vue";
import ListingTable from "@/components/listings/listing-table";
import DatePicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect'
import LoadingSpinner from '@/components/Loading'
import 'vue2-datepicker/index.css';
import StatusBadge from "@/components/buttons/tenancy-status";

export default {
	components: {
		Layout,
		PageHeader,
		ConfirmationModal,
		MessageModal,
		ListingTable,
		//AddModal,
		//InputGroup,
		Multiselect,
		LoadingSpinner,
		// InputModal,
		DatePicker,
		StatusBadge
		// SelectGroup
	},
	data() {
		return {
			title: "Tenancy",
			items: [
				{
					text: "Tenancy",
				},
			],
			filter: {
				// "tenancy": {
					// "created": '',
					// "status": "",
					// "tenant_first_name": '',
					// "tenant_last_name": '',
					// "landlord_first_name": '',
					// "landlord_last_name": '',

				// },
				"order_display_id": "",
				"created": "",
				"status": "",
				"member_id": "",
			},
			fields: [
				{ key: "tenancy.order_display_id", sortable: false, label: "ID" },
				{ key: "creator_name", sortable: false, label: "Creator" },
				{ key: "tenancy.created_at", sortable: false, label: "Date" },
				{
					key: "tenancy.status", sortable: false, label: "Status",
					formatter: value => {
						if (value != null)
							return value.charAt(0).toUpperCase() + value.slice(1);
						else
							return '-';
					}
				},
				{ key: "payment_method", sortable: false, label: "Payment Method" },
				{ key: "details", sortable: false, label: "Details" },
				{ key: "action" },
			],
			membersOptions: [],
			statusOption: [
				{ name: 'all', label: 'All' },
				{ name: 'draft', label: 'Draft' },
				{ name: 'payment_processing', label: 'Payment Processing' },
				{ name: 'pending_signature', label: 'Pending Signature' },
				{ name: 'pending_landlord_signature', label: 'Pending Landlord Signature' },
				{ name: 'pending_tenant_signature', label: 'Pending Tenant Signature' },
				{ name: 'pending_submit', label: 'Pending Submit' },
				{ name: 'pending_stamp', label: 'Pending Stamping' },
				{ name: 'completed', label: 'Completed' },
				{ name: 'expired', label: 'Expired' },
				{ name: 'pending_payment', label: 'Pending Payment' }
			],
			selectedStatus: '',
			selectedMember: '',
			add_tenancy_to: null,
			add_loading: false,
		};
	},
	computed: {},
	watch: {},
	async created() {
		// badgeColor('tenancy', 'draft');
		this.retrieveMemberOptions();
	},
	methods: {
		async retrieveMemberOptions() {
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/member-list`, {
				headers: authHeader(),
			}).catch(function (error) {
				alert("Load members opionts error:" + error.message);
			});

			if (result.data.code === 'success') {
				this.membersOptions = result.data.data;
			}
		},

		setAdminView(filter) {
			this.filter = Object.assign(this.filter, filter);
		},

		filterTable() {
			this.filter.member_id = this.selectedMember ? this.selectedMember.id : null;
			this.filter.status = this.selectedStatus ? this.selectedStatus.name : null;
			const filter = this.filter;
			this.$refs.listingTable.filterTable(filter);
		},

		// badgeClass(val) {
		//   return badgeColor("tenancy", val);
		// },

		// badgeText(val) {
		//   return statusLabel(val);
		// },


		resetTable() {
			this.filter = {
				// "tenancy": {
					// "created": '',
					// "status": "",
					// "tenant_first_name": '',
					// "tenant_last_name": '',
					// "landlord_first_name": '',
					// "landlord_last_name": '',
				// },
				// "order_display_id": "",
				"status": "all",
				// "created": "",
				// "member_id": "",
			};
			this.selectedStatus = '';
			this.selectedMember = '';
			const filter = this.filter;
			this.$refs.listingTable.filterTable(filter);
		},


		/***
		 * Input Modal
		 */
		showAddModal() {
			this.$refs.InputModal.showModal();
			this.add_tenancy_to = null;
		},
		closeAddModal() {
			this.$refs.InputModal.closeModal();
		},
		// async createTenancy() {
		//   if (this.add_tenancy_to.id) {
		//     this.add_loading = true;

		//     const result = await axios.post(`${process.env.VUE_APP_APIURL}/tenancy/create-tenancy`, {
		//       "member_id": this.add_tenancy_to.id
		//     }, {
		//       headers: authHeader(),
		//     }).catch( function (error) {
		//       alert("fail to create:" + error.message);
		//     });

		//     if (result.data.code === 'success') {
		//       this.$router.push({ 'name': "Tenancy Edit", params: { id: result.data.data.id } })
		//       this.add_loading = false;
		//     } else if (result.data.code === "permission_denied") {
		//       this.add_loading = false;
		//       this.$refs.InputModal.closeModal();
		//       this.$refs.messageModal.showModal("Permission Denied");
		//     } else {
		//       this.add_loading = false;
		//     }
		//   }
		// },
		async deleteRecord(order_id, tenancy_id, tenancy_order_id) {
			const deleteCallback = async () => {
				const result = await axios.delete(`${process.env.VUE_APP_APIURL}/tenancy/${order_id}`, {
					headers: authHeader(),
				});
				if (result.data.code === 'success') {
					this.resetTable();
					this.$refs.confirmationModal.closeModal();
					this.$refs.messageModal.showModal("The record has been deleted");
				} else if (result.data.code === "permission_denied") {
					this.$refs.confirmationModal.closeModal();
					this.$refs.messageModal.showModal("Permission Denied");
				}
			};
			this.isLoading = true;
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/tenancy/check-ctc-included/${tenancy_id}`, {
				headers: authHeader(),
			});
			if (result.data.code === 'success') {
				const { data } = result.data;
				let ctcNum = data.length;
				let msg;
				if (ctcNum > 0) {
					const ctc_id = data.reduce((getText, item, index) => {
						let delimeter = index === ctcNum - 1 ? '' : ', ';
						return getText + item.order_display_id + delimeter;
					}, '');

					msg = `Ctc under current tenancy is - ${ctcNum} with id ${ctc_id}! \n
          Are you sure you want to delete tenancy - ${tenancy_order_id}? \n Note:All ctc attached with it will deleted as well?`;
				} else {
					msg = `Are you sure you want to delete the tenancy with ID ${tenancy_order_id}?`;
				}
				this.$refs.confirmationModal.showModal(msg, deleteCallback);
			}
		},
		htmlDecode(text) {
			var textArea = document.createElement('textarea');
			textArea.innerHTML = text;
			return textArea.value;
		}
	},
	mounted() {
	}
};
</script>

<template>
	<Layout>
		<LoadingSpinner :isLoading="add_loading" />
		<PageHeader :title="title" :items="items" />
		<ConfirmationModal ref="confirmationModal" />
		<MessageModal ref="messageModal" />
		<!-- <InputModal title="Create Tenancy" ref="InputModal">
      <template #inputField>
        <label for="tenancy_id">Create a tenancy for:</label>
        <multiselect v-model="add_tenancy_to" :options="membersOptions" placeholder="Created By"
          track-by="id"
          label="full_name">
        </multiselect>
        <div class="d-flex mt-2">
          <button class="btn btn-orange" @click="createTenancy">
            Confirm
          </button>
          <button class="btn btn-danger ml-2" @click="closeAddModal">
            Cancel
          </button>
        </div>
      </template>
</InputModal> -->
		<div class="row">
			<div class="col-lg-12">
				<div class="card" style="border-radius: 15px;">
					<div class="card-body pt-0 pb-0">
						<!-- <div class="row mt-2 ml-0"> -->
						<!-- <button class="btn btn-success" @click="showAddModal">
                <i class="mdi mdi-plus mr-2"></i> Add Tenancy
              </button> -->
						<!-- <router-link class="btn btn-success mb-2" :to="{name: 'Staff Form', params: {id: 'add'}}"><i class="mdi mdi-plus mr-2"></i> Add Tenancy</router-link> -->
						<!-- </div> -->
						<div class="row mt-2">
							<!-- <div class="col-sm-12 col-md-3 col-lg-3 mt-2">
								<label for="tenant_first_name" class="mb-0">Tenant First Name</label>
								<input type="text" placeholder="Tenant First Name" name="tenant_first_name"
									id="tenant_first_name" class="form-control"
									v-model="filter.tenancy.tenant_first_name">
								<label for="tenant_last_name" class="mt-2 mb-0">Tenant Last Name</label>
								<input type="text" placeholder="Tenant Last Name" name="tenant_last_name"
									id="tenant_last_name" class="form-control"
									v-model="filter.tenancy.tenant_last_name">
							</div>

							<div class="col-sm-12 col-md-3 col-lg-3 mt-2">
								<label for="" class="mb-0">Landlord First Name</label>
								<input type="text" placeholder="Landlord First Name" name="landlord_first_name"
									id="landlord_first_name" class="form-control"
									v-model="filter.tenancy.landlord_first_name">
								<label for="" class="mt-2 mb-0">Landlord Last Name</label>
								<input type="text" placeholder="Landlord Last Name" name="landlord_last_name"
									id="landlord_last_name" class="form-control"
									v-model="filter.tenancy.landlord_last_name">
							</div> -->

							<div class="col-12 col-md-3">
								<label for="tenancy_id" class="mt-2 mb-0">ID</label>
								<input type="text" placeholder="ID" name="tenancy_id" id="tenancy_id" class="form-control" v-model="filter.order_display_id">
							</div>

							<div class="col-12 col-md-3">
								<label for="tenancy_id" class="mt-2 mb-0">Status</label>
								<multiselect v-model="selectedStatus" :options="statusOption" placeholder="Status"
									track-by="name" label="label">
								</multiselect>
							</div>

							<div class="col-12 col-md-3">
								<label for="date_from" class="mt-2 mb-0">Created Date:</label>
								<date-picker v-model="filter.created" placeholder="Created Date" value-type="YYYY-MM-DD"></date-picker>
							</div>

							<div class="col-12 col-md-3">
								<label for="date_from" class="mt-2 mb-0">Creator:</label>
								<multiselect v-model="selectedMember" :options="membersOptions" placeholder="Creator"
									track-by="id" label="full_name">
								</multiselect>
							</div>

							<div class="col-12 d-flex justify-content-end" style="margin-top: 12px;">
								<a href="javascript:void(0);" class="btn btn-orange" @click="filterTable">
									Filter
								</a>
								<a href="javascript:void(0);" class="btn btn-filter" style="margin-left: 11px;" @click="resetTable">
									Reset
								</a>
							</div>
						</div>
					</div>

					<div class="pl-4 pr-4">
						<ListingTable ref="listingTable" :fields="fields" :setAdminView="setAdminView" path="tenancy"
							listName="orders">

							<!-- action -->
							<template v-slot:cell(action)="{ item }">
								<router-link class="mr-2 grey-icon-color"
									:to="{ name: 'Tenancy View New', params: { id: item.id } }">
									<i class="ri-eye-line font-size-18" v-b-tooltip.hover title="View"></i>
								</router-link>

								<!-- <router-link
                  class="mr-3 text-primary" v-b-tooltip.hover
                  title="Edit"
                  :to="{ name: 'Tenancy View', params: { id: item.id } }">
                    <i class="ri-pencil-line font-size-18"></i>
                </router-link> -->
								<!-- {{ item.tenancy.id }} -->
								<a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
									@click="deleteRecord(item.id, item.tenancy.id, item.tenancy.order_display_id)">
									<i class="ri-delete-bin-line font-size-18"></i>
								</a>
							</template>

							<template v-slot:cell(creator_name)="row">
								<router-link class="text-primary" :to="{name: 'Member View', params: {id: row.item.creator_id}}">{{ row.item.creator_name }}</router-link>
							</template>

							<!-- Details -->
							<template v-slot:cell(details)="row">
								<p class="mb-0"><strong>Property Name:</strong> {{ row.item.tenancy.title == null ? '-' : htmlDecode(row.item.tenancy.title) }}</p>
								<p class="mb-0"><strong>Landlord Name:</strong> {{ row.item.landlord_name == ' ' ? '-' : row.item.landlord_name }}</p>
								<p class="mb-0"><strong>Tenant Name:</strong> {{ row.item.tenant_name == ' ' ? '-' : row.item.tenant_name }}</p>
								<p class="mb-0"><strong>Period:</strong> {{ row.item.tenancy.start == null && row.item.tenancy.end == null ? '-' : row.item.tenancy.start + ' to ' + row.item.tenancy.end }}</p>
							</template>

							<!-- status -->
							<template v-slot:cell(tenancy.status)="row">
								<StatusBadge :status="row.value" type="tenancy" v-if="row.value != ''" />
							</template>
						</ListingTable>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>
